.workout-calendar {
    .fc-dayGridMonth-view {
        overflow-x: scroll;
    }

    .fc-scrollgrid {
        width: auto;
        overflow-x: auto;
    }

    .fc-scrollgrid, & td, & th {
        border: unset;
    }

    .fc-header-toolbar {
        & div.fc-toolbar-chunk:first-child {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            & > button {
                border: unset;
                outline: unset;
                padding: unset;
                color: #32395F;
                font-size: 24px;
                background-color: unset;

                &:hover, &:active {
                    border: unset;
                    outline: unset;
                }
            }

            & > h2 {
                color: #32395F;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
            }

            & > :not(:first-child) {
                margin-left: 0;
            }

        }
    }

    & thead {
        .fc-col-header-cell-cushion {
            color: #737791;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.02px;
            text-decoration-line: unset;

            @media screen and (max-width: 992px) {
                & {
                    font-size: 16px;
                }
            }

            @media screen and (max-width: 768px) {
                & {
                    font-size: 12px;
                }
            }
        }
    }

    & tbody {

        .fc-day {
            padding: 5px;

            &.fc-day-today {
                background-color: transparent;
            }

            .fc-daygrid-day-frame {
                border-radius: 8px;
                border: 1px solid #EAEFF3;
                box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.05);
                background-color: #FFFFFF;

                & div:nth-child(2) {
                    position: absolute;
                    bottom: -1px;
                    right: -2px;
                }
            }

            .fc-daygrid-day-top {
                display: flex;
                width: fit-content;
                height: 30px;
                padding: 10px 40px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 0 0 84px 0;
                background: #F6F6F6;

                .fc-daygrid-day-number {
                    color: #32395F;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 18px;
                    letter-spacing: -0.014px;
                    text-decoration-line: unset;
                }

                @media screen and (max-width: 992px) {
                    padding: 4px 12px;

                    & .fc-daygrid-day-number {
                        font-size: 10px;
                    }
                }
            }

            .fc-daygrid-day-events {
                .fc-daygrid-event-harness {
                    .workout-type {
                        display: flex;
                        width: fit-content;
                        height: 30px;
                        padding: 6px 20px;
                        justify-content: center;
                        align-items: end;
                        gap: 10px;
                        border-radius: 65px 0 0 0;
                        border: none;
                        position: absolute;
                        /*margin-top: 42.5%;*/
                        right: 0;

                        .fc-event-title {
                            color: #32395F;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 18px;
                            letter-spacing: -0.012px;
                        }

                        &.upper-body {
                            background: #FAEB00;
                        }

                        &.lower-body {
                            background: #00F5F1;
                        }

                        &.metcon {
                            background: #FF7D50;
                        }

                        &.anterior {
                            background: #BDFF00;
                        }

                        &.posterior {
                            background: #FC29FF;
                        }

                        &.ath-conditioning {
                            background: #A37FFF;
                        }

                        @media screen and (max-width: 992px) {
                            padding: 4px 12px;

                            & .fc-event-title {
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .fc {
            width: auto;
            height: 600px;

            .fc-day {
                width: 100px;
            }

            .fc-header-toolbar {
                & div.fc-toolbar-chunk:first-child {
                    width: 100%;
                }
            }
        }
    }
}
