.calendar {
    /* display: grid; */
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
    /* max-width: 600px; */
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    grid-column: span 7;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .month-display {
    text-align:left;
    font-size: 24px;
    font-weight: bold;
  }
  
  .arrow {
    cursor: pointer;
    margin: 0 10px;
  }
  
  .day-names {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(7, 1fr);
    font-weight: bold;
    text-align: center;
  }
  
  .day {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: 1px solid #d1d1d1;
    min-height: 202px;
    height: 100%;
  }
  
  .today {
    background-color: #eaf6ff;
  }
  
  .different-month {
    color: #ccc;
  }
  
  .selected {
    background-color: #eaf6ff;
  }
  
  .event-container {
    margin-top: 5px;
    width:100%;
    text-align:center;
  }
  
    .event {
        background-color: #2ecc71;
        color: #fff;
        padding: 5px 5px;
        border-radius: 3px;
        margin-top: 2px;
        width: 100%;
  }

  .date-button {
    color: #000;
    border: none;
    font-size: 14px;
    background: none;
    cursor: pointer;
    margin-top: 5px;
  }
.workout{
  background: #ff964a;
  color: #fff;
  width: 120px;
  text-align: center;
  padding: 5px;
  display: block;
  margin: 0 auto;
}

  .abc {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: space-between;
}
  .week{
    display:grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
  }

  .day-names .day{
    height:100%;
    border: none;
    min-height:50px ;
  }
  
  .day-number {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
}
.workout_status{
  padding: 10px;
}

.day-number p {
    width: 30px;
    height: 30px;
    background: #80c522;
    color: #fff;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
}

.different-month-day {
    display:none !important;
    
}