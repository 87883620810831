.grocery-modal {
    .btn {
        padding-left: 30px;
        padding-right: 30px;
    }

    .btn-export {
        color: #F36F27;
        border-color: #F36F27;
        background-color: #FFFFFF;
    }

    .btn-order-groceries {
        border-color: #F36F27;
        background-color: #F36F27;
    }

    .btn-add-items {
        color: #A0AEC0;
        border-color: #F1F2F4;
        background-color: #F1F2F4;
    }

    .btn-remove-items {
        color: #FFFFFF;
        border-color: #F36F27;
        background-color: #F36F27;
    }

    .card {
        height: 100%;
        min-height: 200px;
        border-radius: 19px;

        .card-header {
            color: #FFFFFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            text-align: center;
            background: #3A3A3A;
            border-radius: 19px 19px 0 0;
        }

        .card-body.items {
            .item > .quantity-unit {
                min-width: 60px;
                color: #687588;
                font-weight: 400;
            }

            .item > .title {
                color: #191D31;
                font-weight: 700;
            }
        }
    }
}

.grocery-store-modal {
    background: rgba(0, 0, 0, 0.50);

    .modal-content {
        border-radius: 19px;
        border: 1px solid #E7E7E7;
    }

    .modal-body {
        .btn {
            padding: 10px;
            border-radius: 10px;
            border-color: #F36F27;
            background: #F36F27;

            font-size: 16px;
            font-style: normal;
            font-weight: 700;
        }
    }
}

.grocery-add-new-item-modal {
    background: rgba(0, 0, 0, 0.50);

    .modal-content {
        border-radius: 19px;
        border: 1px solid #E7E7E7;
    }

    .modal-body {
        .form-control {
            padding: 10px;
        }

        .btn.cancel {
            color: #111827;
            padding: 10px;
            border-radius: 10px;
            border-color: #111827;

            font-size: 16px;
            font-style: normal;
            font-weight: 700;

            &:hover {
                color: #FFFFFF;
                background-color: #111827;
            }
        }

        .btn.add-item {
            padding: 10px;
            border-radius: 10px;
            border-color: #F36F27;
            background: #F36F27;

            font-size: 16px;
            font-style: normal;
            font-weight: 700;
        }
    }
}