.dashboard-hours .item {
    display: flex;
    padding: 15px;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    border-radius: 16px;
    background: #FFF;
    border-width: 3px;
    border-style: solid;
    width: 100%;
    height: 100%;
    object-fit: contain;
    justify-content: center;
}

.dashboard-hours .item.move {
    color: #24D1DD;
    border-color: #24D1DD;
}

.dashboard-hours .item.exercise {
    color: #FFD600;
    border-color: #FFD600;
}

.dashboard-hours .item.stand {
    color: #DF18FF;
    border-color: #DF18FF;
}

.dashboard-hours .item.steps {
    color: #00FF0A;
    border-color: #00FF0A;
}

.dashboard-hours .item.distance {
    color: #FF5C00;
    border-color: #FF5C00;
}

.dashboard-hours .item svg {
    height: 60px;
}

.dashboard-hours .item .title {
    color: #191919;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: -0.6px;
}

.dashboard-hours .item .sub-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
}

.dashboard-recipes {
    border-radius: 16px;
    border: 1px solid #F0F3F6;
    background: #FFF;
    flex-shrink: 0;
    position: relative;
}

.dashboard-recipes .title {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 23.4px */
}

.dashboard-recipes .recipes .recipe {
    width: 100%;
    border-radius: 16px;
    border: 1px solid #F0F3F6;
    background: #FFF;
}

.dashboard-recipes .recipes .recipe img {
    border-radius: 9px;
}

.dashboard-recipes .recipes .recipe .title {
    color: #19191C;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-top: 10px;
}

.dashboard-recipes .recipes .recipe .sub-title {
    color: #959595;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 10px;
}

.dashboard-schedules {
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
}

.dashboard-schedules .schedule {
    height: 150px;
    border-radius: 14px;
    margin-top: 15px;
    border: 1px solid #E7E7E7;
    background: #FFFFFF;
}

.dashboard-schedules .schedule.active {
    border-radius: 16px;
    border: 1px solid #FF5C00;
    background: #FF5C00;
    color: #FFFFFF;
}

.dashboard-schedules .schedule .details {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.dashboard-schedules .schedule .title {
    color: #191919;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.36px;
}

.dashboard-schedules .schedule .sub-title {
    color: #757575;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
}

.dashboard-schedules .schedule .date {
    color: #191919;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.dashboard-schedules .schedule.active .title,
.dashboard-schedules .schedule.active .sub-title,
.dashboard-schedules .schedule.active .date {
    color: #FFFFFF;
}

.dashboard-schedules .schedule .badge {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
    padding: 8px 20px;
    border-radius: 8px;
}

.dashboard-schedules .schedule .badge.begin {
    background: #8DC53E !important;
}

.dashboard-schedules .schedule .badge.upcoming {
    background: #F36F27 !important;
}

.dashboard-schedules .schedule .badge.completed {
    background: #FF8264 !important;
}
