.sidebar {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 300px;
    background-color: #FFFFFF !important;
    position: relative;
    z-index: 1;
    transition: all 0.5s;

    & .main-logo {
        display: flex;
        height: 67px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        align-self: stretch;
    }
}

.sidebar .navigation {
    & button.close {
        display: none;
    }
}

.sidebar .logo img:first-child {
    width: 73px;
    height: 51px;
    display: none;
}

.sidebar .logo img:last-child {
    width: 240px;
    height: 40px;
    flex-shrink: 0;
}

.sidebar .sections {
    display: flex;
    padding: 0 12px;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 0;
    align-self: stretch;
    overflow: auto;
}

.sidebar .nav-pills {
    display: flex;
    flex-direction: column;
    gap: 18px;
    align-self: stretch;
}

.sidebar .nav-pills .nav-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.sidebar .nav-pills .nav-item > .nav-link {
    display: flex;
    padding: 16px 20px 16px 28px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.sidebar .nav-pills .nav-item .nav-link span {
    color: #737791;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.016px;
    flex: 1 0 0;
}

.sidebar .nav-pills .nav-item.main-menu .nav-link svg:last-child {
    transform: rotate(-90deg);
}

.sidebar .nav-pills .nav-item.main-menu .nav-link.active svg:last-child {
    transform: rotate(0deg);
}

.sidebar .nav-pills .nav-item .nav-link.active svg:last-child,
.sidebar .nav-pills .nav-item .nav-link:hover svg:last-child {
    stroke: #F36F27 !important;
}

.sidebar .nav-pills .nav-item .nav-link.active span,
.sidebar .nav-pills .nav-item .nav-link:hover span {
    color: #FFFFFF;
}

.sidebar .nav-pills .nav-item .nav-link.active,
.sidebar .nav-pills .nav-item .nav-link:hover {
    border-radius: 8px;
    background: #F36F27;
}

.sidebar .nav-pills .nav-item.main-menu .accordion-collapse {
    width: 100%;
}

.sidebar .nav-pills .nav-item.main-menu .nav-link.active,
.sidebar .nav-pills .nav-item.main-menu .nav-link:hover {
    border-radius: 8px;
    background: #F6F6F6;
}

.sidebar .nav-pills .nav-item.main-menu .nav-link.active svg,
.sidebar .nav-pills .nav-item.main-menu .nav-link:hover svg {
    fill: #F36F27 !important;
}

.sidebar .nav-pills .nav-item.main-menu .nav-link.active span,
.sidebar .nav-pills .nav-item.main-menu .nav-link:hover span {
    color: #F36F27;
}

.sidebar .nav-pills .nav-link.active svg,
.sidebar .nav-pills .nav-link:hover svg {
    fill: #ffffff !important;
}

.popover.sub-menu {
    border-radius: 16px;
    border: 1px solid #F6F6F6;
    background: #FFF;

    /* 0 */
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.02);
    /*box-shadow: 0 2.767px 2.214px 0 rgba(0, 0, 0, 0.02), 0 6.65px 5.32px 0 rgba(0, 0, 0, 0.03), 0 12.522px 10.017px 0 rgba(0, 0, 0, 0.04), 0 22.336px 17.869px 0 rgba(0, 0, 0, 0.04), 0 41.778px 33.422px 0 rgba(0, 0, 0, 0.05), 0 100px 80px 0 rgba(0, 0, 0, 0.07);*/
}

.popover.sub-menu .popover-body {
    display: flex;
    padding: 12px;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
}

.sidebar .nav-pills .nav-item .sub-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    margin-left: 40px;
}

.popover.sub-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    margin-left: 28px;
}

.popover.sub-menu.hide {
    margin-left: 18px;
}

.sidebar .nav-pills .nav-item .sub-menu .nav-link,
.popover.sub-menu .nav-link {
    color: #737791;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 157.143% */
    letter-spacing: -0.014px;
}

.sidebar .nav-pills .nav-item .sub-menu .nav-link.active,
.sidebar .nav-pills .nav-item .sub-menu .nav-link:hover,
.popover.sub-menu .nav-link.active,
.popover.sub-menu .nav-link:hover {
    color: #FFFFFF;
    border-radius: 8px;
    background: #F36F27;
}

.sidebar .nav-pills .nav-item .sub-menu .nav-item {
    --spacing: 1.5rem;
    padding-left: 20px;
    position: relative;
    border-left: 2px solid #F6F6F6;
}

.sidebar .nav-pills .nav-item .sub-menu .nav-item .nav-link {
    display: flex;
    height: 44px;
    padding: 16px 20px;
    align-items: center;
    gap: 12px;
}

.sidebar .nav-pills .nav-item .sub-menu .nav-item:last-child {
    border-color: transparent;
}

.sidebar .nav-pills .nav-item .sub-menu .nav-item::before {
    content: '';
    display: block;
    position: absolute;
    top: calc(var(--spacing) / -3);
    left: -2px;
    width: calc(var(--spacing) - 8px);
    height: calc(var(--spacing) + 8px);
    border-bottom-left-radius: 10px;
    border: solid #F6F6F6;
    border-width: 0 0 2px 2px;
}

.sidebar hr {
    height: 2px;
    background: #F6F6F6;
}

.sidebar .user-card {
    display: flex;
    padding: 16px 20px 16px 12px;
    align-items: center;
    gap: 12px;
}

.sidebar .user-card .details {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
}

.sidebar .user-card .details .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
}

.sidebar .user-card .arrow {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.sidebar button.menu-toggle {
    display: flex;
    padding: 6px;
    align-items: flex-start;
    gap: 8px;
    position: absolute;
    right: -13.5px;
    top: 51px;
    border-radius: 8px;
    border: 1px solid #F6F6F6;
    background: #FFF;
}

.sidebar button.menu-toggle:hover {
    background: #F36F27;
}

.sidebar button.menu-toggle:hover svg {
    stroke: #FFFFFF;
}

.popover.sub-menu .nav-item {
    width: 100%;
}

.sidebar .sections .bottom {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: 20px;
}

.sidebar .sections .bottom .nav-item {
    background: #F6F6F6;
    border-radius: 8px;
}

.sidebar .sections .bottom .nav-item:hover svg {
    fill: #FFFFFF;
}

.sidebar .sections .bottom .nav-item:hover {
    color: #FFFFFF;
    background: #F36F27;
}

.sidebar-desktop {
    .navigation {
        & button.icon {
            display: none;
        }
    }

    .sidebar.hide {
        display: flex;
        width: 125px;
        padding: 30px 0 32px 0;
        flex-direction: column;
        align-items: center;
        align-self: stretch;

        & button.menu-toggle svg {
            transform: rotate(180deg);
        }
    }

    .sidebar.hide .logo img:first-child {
        display: block;
    }

    .sidebar.hide .logo img:last-child {
        display: none;
    }

    .sidebar.hide .nav-pills .nav-item .nav-link,
    .sidebar.hide .nav-pills .nav-item.main-menu .nav-link,
    .sidebar.hide .nav-pills .nav-item .nav-link svg:last-child,
    .sidebar.hide .nav-pills .nav-item.main-menu .nav-link svg:last-child {
        width: fit-content;
        padding: 16px 20px;
    }

    .sidebar.hide .nav-pills .nav-item .nav-link span,
    .sidebar.hide .nav-pills .nav-item.main-menu .nav-link span,
    .sidebar.hide .nav-pills .nav-item .nav-link svg:last-child,
    .sidebar.hide .nav-pills .nav-item.main-menu .nav-link svg:last-child {
        display: none;
    }

    .sidebar.hide .user-card .details .right {
        display: none;
    }

    .sidebar.hide .user-card .arrow {
        display: none;
    }
}

.sidebar-mobile {
    &.sidebar-hide {
        .navigation {
            border-bottom: 1px solid #E9EAEC;
            box-shadow: 0 3px 5px -3px #E9EAEC;
        }

        .sidebar {
            display: none !important;
        }
    }

    &.sidebar-show {
        & .navigation {
            .main-logo {
                height: auto;
            }
        }

        & .sidebar {
            padding: 0 !important;
        }
    }

    & .navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 25px;

        & button.icon {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 20px;
            background-color: transparent;
            border: unset;
        }

        & .logo {
            width: 100%;
            flex-shrink: 1;
        }

        & img:last-child {
            display: flex;
            width: 200px;
            height: 32px;
            justify-content: flex-end;
            align-items: center;
            gap: 24px;
            flex-shrink: 0;
        }
    }

    & .sidebar {
        width: 100%;

        & button.menu-toggle {
            display: none;
        }
    }
}
